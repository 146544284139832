'use client';
import * as React from 'react';
import warning from '../_util/warning';
import { changeConfirmLocale } from '../modal/locale';
import LocaleContext from './context';
export { default as useLocale } from './useLocale';
export const ANT_MARK = 'internalMark';
const LocaleProvider = (props) => {
    const { locale = {}, children, _ANT_MARK__ } = props;
    if (process.env.NODE_ENV !== 'production') {
        warning(_ANT_MARK__ === ANT_MARK, 'LocaleProvider', '`LocaleProvider` is deprecated. Please use `locale` with `ConfigProvider` instead: http://u.ant.design/locale');
    }
    React.useEffect(() => {
        const clearLocale = changeConfirmLocale(locale && locale.Modal);
        return clearLocale;
    }, [locale]);
    const getMemoizedContextValue = React.useMemo(() => (Object.assign(Object.assign({}, locale), { exist: true })), [locale]);
    return (React.createElement(LocaleContext.Provider, { value: getMemoizedContextValue }, children));
};
if (process.env.NODE_ENV !== 'production') {
    LocaleProvider.displayName = 'LocaleProvider';
}
export default LocaleProvider;
