var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import classNames from 'classnames';
import toArray from 'rc-util/lib/Children/toArray';
import pickAttrs from 'rc-util/lib/pickAttrs';
import * as React from 'react';
import { cloneElement } from '../_util/reactNode';
import warning from '../_util/warning';
import { ConfigContext } from '../config-provider';
import BreadcrumbItem, { InternalBreadcrumbItem } from './BreadcrumbItem';
import BreadcrumbSeparator from './BreadcrumbSeparator';
import useStyle from './style';
import useItemRender from './useItemRender';
import useItems from './useItems';
const getPath = (params, path) => {
    if (path === undefined) {
        return path;
    }
    let mergedPath = (path || '').replace(/^\//, '');
    Object.keys(params).forEach((key) => {
        mergedPath = mergedPath.replace(`:${key}`, params[key]);
    });
    return mergedPath;
};
const Breadcrumb = (props) => {
    const { prefixCls: customizePrefixCls, separator = '/', style, className, rootClassName, routes: legacyRoutes, items, children, itemRender, params = {} } = props, restProps = __rest(props, ["prefixCls", "separator", "style", "className", "rootClassName", "routes", "items", "children", "itemRender", "params"]);
    const { getPrefixCls, direction, breadcrumb } = React.useContext(ConfigContext);
    let crumbs;
    const prefixCls = getPrefixCls('breadcrumb', customizePrefixCls);
    const [wrapSSR, hashId] = useStyle(prefixCls);
    const mergedItems = useItems(items, legacyRoutes);
    if (process.env.NODE_ENV !== 'production') {
        warning(!legacyRoutes, 'Breadcrumb', '`routes` is deprecated. Please use `items` instead.');
    }
    const mergedItemRender = useItemRender(prefixCls, itemRender);
    if (mergedItems && mergedItems.length > 0) {
        // generated by route
        const paths = [];
        const itemRenderRoutes = items || legacyRoutes;
        crumbs = mergedItems.map((item, index) => {
            const { path, key, type, menu, overlay, onClick, className: itemClassName, separator: itemSeparator, dropdownProps, } = item;
            const mergedPath = getPath(params, path);
            if (mergedPath !== undefined) {
                paths.push(mergedPath);
            }
            const mergedKey = key !== null && key !== void 0 ? key : index;
            if (type === 'separator') {
                return React.createElement(BreadcrumbSeparator, { key: mergedKey }, itemSeparator);
            }
            const itemProps = {};
            const isLastItem = index === mergedItems.length - 1;
            if (menu) {
                itemProps.menu = menu;
            }
            else if (overlay) {
                itemProps.overlay = overlay;
            }
            let { href } = item;
            if (paths.length && mergedPath !== undefined) {
                href = `#/${paths.join('/')}`;
            }
            return (React.createElement(InternalBreadcrumbItem, Object.assign({ key: mergedKey }, itemProps, pickAttrs(item, { data: true, aria: true }), { className: itemClassName, dropdownProps: dropdownProps, href: href, separator: isLastItem ? '' : separator, onClick: onClick, prefixCls: prefixCls }), mergedItemRender(item, params, itemRenderRoutes, paths, href)));
        });
    }
    else if (children) {
        const childrenLength = toArray(children).length;
        crumbs = toArray(children).map((element, index) => {
            if (!element) {
                return element;
            }
            // =================== Warning =====================
            if (process.env.NODE_ENV !== 'production') {
                warning(!element, 'Breadcrumb', '`Breadcrumb.Item and Breadcrumb.Separator` is deprecated. Please use `items` instead.');
            }
            warning(element.type &&
                (element.type.__ANT_BREADCRUMB_ITEM === true ||
                    element.type.__ANT_BREADCRUMB_SEPARATOR === true), 'Breadcrumb', "Only accepts Breadcrumb.Item and Breadcrumb.Separator as it's children");
            const isLastItem = index === childrenLength - 1;
            return cloneElement(element, {
                separator: isLastItem ? '' : separator,
                key: index,
            });
        });
    }
    const breadcrumbClassName = classNames(prefixCls, breadcrumb === null || breadcrumb === void 0 ? void 0 : breadcrumb.className, {
        [`${prefixCls}-rtl`]: direction === 'rtl',
    }, className, rootClassName, hashId);
    const mergedStyle = Object.assign(Object.assign({}, breadcrumb === null || breadcrumb === void 0 ? void 0 : breadcrumb.style), style);
    return wrapSSR(React.createElement("nav", Object.assign({ className: breadcrumbClassName, style: mergedStyle }, restProps),
        React.createElement("ol", null, crumbs)));
};
Breadcrumb.Item = BreadcrumbItem;
Breadcrumb.Separator = BreadcrumbSeparator;
if (process.env.NODE_ENV !== 'production') {
    Breadcrumb.displayName = 'Breadcrumb';
}
export default Breadcrumb;
