import LoadingOutlined from '@ant-design/icons/LoadingOutlined';
import classNames from 'classnames';
import CSSMotion from 'rc-motion';
import React, { forwardRef } from 'react';
import IconWrapper from './IconWrapper';
const InnerLoadingIcon = forwardRef(({ prefixCls, className, style, iconClassName }, ref) => {
    const mergedIconCls = classNames(`${prefixCls}-loading-icon`, className);
    return (React.createElement(IconWrapper, { prefixCls: prefixCls, className: mergedIconCls, style: style, ref: ref },
        React.createElement(LoadingOutlined, { className: iconClassName })));
});
const getCollapsedWidth = () => ({
    width: 0,
    opacity: 0,
    transform: 'scale(0)',
});
const getRealWidth = (node) => ({
    width: node.scrollWidth,
    opacity: 1,
    transform: 'scale(1)',
});
const LoadingIcon = (props) => {
    const { prefixCls, loading, existIcon, className, style } = props;
    const visible = !!loading;
    if (existIcon) {
        return React.createElement(InnerLoadingIcon, { prefixCls: prefixCls, className: className, style: style });
    }
    return (React.createElement(CSSMotion, { visible: visible, 
        // We do not really use this motionName
        motionName: `${prefixCls}-loading-icon-motion`, removeOnLeave: true, onAppearStart: getCollapsedWidth, onAppearActive: getRealWidth, onEnterStart: getCollapsedWidth, onEnterActive: getRealWidth, onLeaveStart: getRealWidth, onLeaveActive: getCollapsedWidth }, ({ className: motionCls, style: motionStyle }, ref) => (React.createElement(InnerLoadingIcon, { prefixCls: prefixCls, className: className, style: Object.assign(Object.assign({}, style), motionStyle), ref: ref, iconClassName: motionCls }))));
};
export default LoadingIcon;
