export function getPlaceholder(locale, picker, customizePlaceholder) {
    if (customizePlaceholder !== undefined) {
        return customizePlaceholder;
    }
    if (picker === 'year' && locale.lang.yearPlaceholder) {
        return locale.lang.yearPlaceholder;
    }
    if (picker === 'quarter' && locale.lang.quarterPlaceholder) {
        return locale.lang.quarterPlaceholder;
    }
    if (picker === 'month' && locale.lang.monthPlaceholder) {
        return locale.lang.monthPlaceholder;
    }
    if (picker === 'week' && locale.lang.weekPlaceholder) {
        return locale.lang.weekPlaceholder;
    }
    if (picker === 'time' && locale.timePickerLocale.placeholder) {
        return locale.timePickerLocale.placeholder;
    }
    return locale.lang.placeholder;
}
export function getRangePlaceholder(locale, picker, customizePlaceholder) {
    if (customizePlaceholder !== undefined) {
        return customizePlaceholder;
    }
    if (picker === 'year' && locale.lang.yearPlaceholder) {
        return locale.lang.rangeYearPlaceholder;
    }
    if (picker === 'quarter' && locale.lang.quarterPlaceholder) {
        return locale.lang.rangeQuarterPlaceholder;
    }
    if (picker === 'month' && locale.lang.monthPlaceholder) {
        return locale.lang.rangeMonthPlaceholder;
    }
    if (picker === 'week' && locale.lang.weekPlaceholder) {
        return locale.lang.rangeWeekPlaceholder;
    }
    if (picker === 'time' && locale.timePickerLocale.placeholder) {
        return locale.timePickerLocale.rangePlaceholder;
    }
    return locale.lang.rangePlaceholder;
}
export function transPlacement2DropdownAlign(direction, placement) {
    const overflow = {
        adjustX: 1,
        adjustY: 1,
    };
    switch (placement) {
        case 'bottomLeft': {
            return {
                points: ['tl', 'bl'],
                offset: [0, 4],
                overflow,
            };
        }
        case 'bottomRight': {
            return {
                points: ['tr', 'br'],
                offset: [0, 4],
                overflow,
            };
        }
        case 'topLeft': {
            return {
                points: ['bl', 'tl'],
                offset: [0, -4],
                overflow,
            };
        }
        case 'topRight': {
            return {
                points: ['br', 'tr'],
                offset: [0, -4],
                overflow,
            };
        }
        default: {
            return {
                points: direction === 'rtl' ? ['tr', 'br'] : ['tl', 'bl'],
                offset: [0, 4],
                overflow,
            };
        }
    }
}
function toArray(list) {
    if (!list) {
        return [];
    }
    return Array.isArray(list) ? list : [list];
}
export function getTimeProps(props) {
    const { format, picker, showHour, showMinute, showSecond, use12Hours } = props;
    const firstFormat = toArray(format)[0];
    const showTimeObj = Object.assign({}, props);
    if (firstFormat && typeof firstFormat === 'string') {
        if (!firstFormat.includes('s') && showSecond === undefined) {
            showTimeObj.showSecond = false;
        }
        if (!firstFormat.includes('m') && showMinute === undefined) {
            showTimeObj.showMinute = false;
        }
        if (!firstFormat.includes('H') &&
            !firstFormat.includes('h') &&
            !firstFormat.includes('K') &&
            !firstFormat.includes('k') &&
            showHour === undefined) {
            showTimeObj.showHour = false;
        }
        if ((firstFormat.includes('a') || firstFormat.includes('A')) && use12Hours === undefined) {
            showTimeObj.use12Hours = true;
        }
    }
    if (picker === 'time') {
        return showTimeObj;
    }
    if (typeof firstFormat === 'function') {
        // format of showTime should use default when format is custom format function
        delete showTimeObj.format;
    }
    return {
        showTime: showTimeObj,
    };
}
export function mergeAllowClear(allowClear, clearIcon, defaultClearIcon) {
    if (allowClear === false) {
        return false;
    }
    const defaults = { clearIcon: clearIcon !== null && clearIcon !== void 0 ? clearIcon : defaultClearIcon };
    return typeof allowClear === 'object' ? Object.assign(Object.assign({}, defaults), allowClear) : defaults;
}
